<template>
  <div class="pages">
    <h1>Editar Câmara</h1>
    <button class="btn delete-btn" v-on:click="deleteItem" :disabled="deleting">{{ deleting ? 'Excluindo...' : 'Excluir' }}</button>
    <button class="btn outline back-btn" v-on:click="$router.back()">Voltar</button>
    <p v-if="loading">Carregando dados...</p>
    <div class="wrapper" v-if="!loading">
      <div>
        <label for="name">Nome</label>
        <input type="text" id="name" v-model="item.name" placeholder="Título">
      </div>
      <div>
        <label for="url">URL <small>ex: camara-do-crars</small></label>
        <input type="text" id="url" v-model="item.url" v-on:keyup="formatURL" placeholder="URL">
      </div>
      <div>
        <label for="img">Logo</label>
        <input type="file" id="img" v-on:change="imgChanged" accept="image/*" ref="img">
        <p v-if="savingImg">Fazendo upload...</p>
        <img v-if="item.logo" :src="$mediaBaseUrl + item.logo" :alt="item.name">
      </div>
      <div>
        <label>Comitê Gestor</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_manager"
              autofocus
              holder-id="codex-editor-content-manager"
              save-button-id="save-button-manager"
              :init-data="initDataManager"
              @save="saveManager"
              @ready="onReadyManager"
              @change="onChangeManager"
          />
        </div>
      </div>
      <div>
        <label>Titulares</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_holders"
              autofocus
              holder-id="codex-editor-content-holders"
              save-button-id="save-button-holders"
              :init-data="initDataHolders"
              @save="saveHolders"
              @ready="onReadyHolders"
              @change="onChangeHolders"
          />
        </div>
      </div>
      <div>
        <label>Suplentes</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_alternate"
              autofocus
              holder-id="codex-editor-content-alternate"
              save-button-id="save-button-alternate"
              :init-data="initDataAlternate"
              @save="saveAlternate"
              @ready="onReadyAlternate"
              @change="onChangeAlternate"
          />
        </div>
      </div>
      <div>
        <label>Data de Criação</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_creation_date"
              autofocus
              holder-id="codex-editor-content-creation-date"
              save-button-id="save-button-creation-date"
              :init-data="initDataCreationDate"
              @save="saveCreationDate"
              @ready="onReadyCreationDate"
              @change="onChangeCreationDate"
          />
        </div>
      </div>
      <div>
        <label>Objetivos</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_goals"
              autofocus
              holder-id="codex-editor-content-goals"
              save-button-id="save-button-goals"
              :init-data="initDataGoals"
              @save="saveGoals"
              @ready="onReadyGoals"
              @change="onChangeGoals"
          />
        </div>
      </div>
      <div>
        <label>Atividades</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_activities"
              autofocus
              holder-id="codex-editor-content-activities"
              save-button-id="save-button-activities"
              :init-data="initDataActivities"
              @save="saveActivities"
              @ready="onReadyActivities"
              @change="onChangeActivities"
          />
        </div>
      </div>
      <div>
        <label>Principais Eventos</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_events"
              autofocus
              holder-id="codex-editor-content-events"
              save-button-id="save-button-events"
              :init-data="initDataEvents"
              @save="saveEvents"
              @ready="onReadyEvents"
              @change="onChangeEvents"
          />
        </div>
      </div>
      <div>
        <label>Informações para Contato</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_contact"
              autofocus
              holder-id="codex-editor-content-contact"
              save-button-id="save-button-contact"
              :init-data="initDataContact"
              @save="saveContact"
              @ready="onReadyContact"
              @change="onChangeContact"
          />
        </div>
      </div>      
      <div>
        <label>FAQ</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_faq"
              autofocus
              holder-id="codex-editor-content-faq"
              save-button-id="save-button-faq"
              :init-data="initDataFaq"
              @save="saveFaq"
              @ready="onReadyFaq"
              @change="onChangeFaq"
          />
        </div>
      </div>
      <div>
        <label>Apoiadores</label>
        <div class="editor-wrapper">
          <editor
              ref="editor_content_sponsors"
              autofocus
              holder-id="codex-editor-content-sponsors"
              save-button-id="save-button-sponsors"
              :init-data="initDataSponsors"
              @save="saveSponsors"
              @ready="onReadySponsors"
              @change="onChangeSponsors"
          />
        </div>
      </div>
      <br>
      <button class="btn save-btn" v-on:click="saveItem" :disabled="saving">{{ saving ? 'Salvando...' : 'Salvar' }}</button>
    </div>

      <div v-if="item.url === 'ceensino'">
      <router-link to="/paginas/editar/341" class="btn"><font-awesome-icon icon="edit"/> Leis/Decretos CEEnsino</router-link>
      <router-link to="/paginas/editar/383" class="btn"><font-awesome-icon icon="edit"/> Artigos CEEnsino</router-link>
       <router-link to="/noticias-camara/ceensino" class="btn"><font-awesome-icon icon="edit"/> Notícias CEEnsino</router-link>       
       <router-link to="/paginas/editar/702" class="btn"><font-awesome-icon icon="edit"/> Vídeos</router-link>
       <router-link to="/paginas/editar/703" class="btn"><font-awesome-icon icon="edit"/> Links</router-link>       
       <router-link to="/paginas/editar/704" class="btn"><font-awesome-icon icon="edit"/> Arquivos</router-link>
      </div>
      <div v-if="item.url === 'cgp'">
      <router-link to="/paginas/editar/344" class="btn"><font-awesome-icon icon="edit"/> Leis/Decretos CGP</router-link>
      <router-link to="/paginas/editar/384" class="btn"><font-awesome-icon icon="edit"/> Artigos CGP</router-link>
      <router-link to="/noticias-camara/cgp" class="btn"><font-awesome-icon icon="edit"/> Notícias CGP</router-link>
      <router-link to="/paginas/editar/705" class="btn"><font-awesome-icon icon="edit"/> Vídeos</router-link>
       <router-link to="/paginas/editar/706" class="btn"><font-awesome-icon icon="edit"/> Links</router-link>              
      <router-link to="/paginas/editar/617" class="btn"><font-awesome-icon icon="edit"/> Arquivos CGP</router-link>
      </div>
      <div v-if="item.url === 'cesaude'">
      <router-link to="/paginas/editar/347" class="btn"><font-awesome-icon icon="edit"/> Leis/Decretos CESaúde</router-link>
      <router-link to="/paginas/editar/385" class="btn"><font-awesome-icon icon="edit"/> Artigos CESaúde</router-link>
      <router-link to="/noticias-camara/cesaude" class="btn"><font-awesome-icon icon="edit"/> Notícias CESaúde</router-link>
      <router-link to="/paginas/editar/707" class="btn"><font-awesome-icon icon="edit"/> Vídeos</router-link>
       <router-link to="/paginas/editar/708" class="btn"><font-awesome-icon icon="edit"/> Links</router-link>       
       <router-link to="/paginas/editar/709" class="btn"><font-awesome-icon icon="edit"/> Arquivos</router-link>
      </div>
      <div v-if="item.url === 'cja'">
      <router-link to="/paginas/editar/350" class="btn"><font-awesome-icon icon="edit"/> Leis/Decretos CRA Jovem</router-link>
      <router-link to="/paginas/editar/386" class="btn"><font-awesome-icon icon="edit"/> Artigos CRA Jovem</router-link>
      <router-link to="/noticias-camara/cja" class="btn"><font-awesome-icon icon="edit"/> Notícias CRA Jovem</router-link>
      <router-link to="/paginas/editar/710" class="btn"><font-awesome-icon icon="edit"/> Vídeos</router-link>
       <router-link to="/paginas/editar/711" class="btn"><font-awesome-icon icon="edit"/> Links</router-link>       
       <router-link to="/paginas/editar/712" class="btn"><font-awesome-icon icon="edit"/> Arquivos</router-link>
      </div>
      <div v-if="item.url === 'cerss'">
      <router-link to="/paginas/editar/353" class="btn"><font-awesome-icon icon="edit"/> Leis/Decretos CERSS</router-link>
      <router-link to="/paginas/editar/387" class="btn"><font-awesome-icon icon="edit"/> Artigos CERSS</router-link>
      <router-link to="/noticias-camara/cerss" class="btn"><font-awesome-icon icon="edit"/> Notícias CERSS</router-link>
      <router-link to="/paginas/editar/713" class="btn"><font-awesome-icon icon="edit"/> Vídeos</router-link>
       <router-link to="/paginas/editar/714" class="btn"><font-awesome-icon icon="edit"/> Links</router-link>       
       <router-link to="/paginas/editar/715" class="btn"><font-awesome-icon icon="edit"/> Arquivos</router-link>
      </div>
      <div v-if="item.url === 'cerint'">
      <router-link to="/paginas/editar/356" class="btn"><font-awesome-icon icon="edit"/> Leis/Decretos CERInt</router-link>
      <router-link to="/paginas/editar/388" class="btn"><font-awesome-icon icon="edit"/> Artigos CERInt</router-link>
      <router-link to="/noticias-camara/cerint" class="btn"><font-awesome-icon icon="edit"/> Notícias CERInt</router-link>
      <router-link to="/paginas/editar/716" class="btn"><font-awesome-icon icon="edit"/> Vídeos</router-link>
       <router-link to="/paginas/editar/717" class="btn"><font-awesome-icon icon="edit"/> Links</router-link>       
       <router-link to="/paginas/editar/718" class="btn"><font-awesome-icon icon="edit"/> Arquivos</router-link>
      </div>
      <div v-if="item.url === 'cetinova'">
      <router-link to="/paginas/editar/359" class="btn"><font-awesome-icon icon="edit"/> Leis/Decretos CETInova</router-link>
      <router-link to="/paginas/editar/389" class="btn"><font-awesome-icon icon="edit"/> Artigos CETInova</router-link>
      <router-link to="/noticias-camara/cetinova" class="btn"><font-awesome-icon icon="edit"/> Notícias CETInova</router-link>
      <router-link to="/paginas/editar/719" class="btn"><font-awesome-icon icon="edit"/> Vídeos</router-link>
       <router-link to="/paginas/editar/720" class="btn"><font-awesome-icon icon="edit"/> Links</router-link>       
       <router-link to="/paginas/editar/721" class="btn"><font-awesome-icon icon="edit"/> Arquivos</router-link>
      </div>
      <div v-if="item.url === 'cma'">
      <router-link to="/paginas/editar/362" class="btn" style="display: inline-block"><font-awesome-icon icon="edit"/> Leis/Decretos CEMA</router-link>
      <router-link to="/paginas/editar/390" class="btn" style="display: inline-block"><font-awesome-icon icon="edit"/> Artigos CEMA</router-link>
      <router-link to="/noticias-camara/cma" class="btn" style="display: inline-block"><font-awesome-icon icon="edit"/> Notícias CEMA</router-link>
      <router-link to="/paginas/editar/403" class="btn" style="display: inline-block"><font-awesome-icon icon="edit"/> Links CEMA</router-link>
      <router-link to="/paginas/editar/404" class="btn" style="display: inline-block"><font-awesome-icon icon="edit"/> Requerimentos CEMA</router-link>
      <router-link to="/paginas/editar/535" class="btn" style="display: inline-block"><font-awesome-icon icon="edit"/> Especialistas MESC's CEMA</router-link>
      <router-link to="/paginas/editar/627" class="btn" style="display: inline-block"><font-awesome-icon icon="edit"/> Vídeos CEMA</router-link>       
       <router-link to="/paginas/editar/722" class="btn"><font-awesome-icon icon="edit"/> Arquivos</router-link>
    </div>
    <div v-if="item.url === 'cempe'">
      <router-link to="/paginas/editar/379" class="btn"><font-awesome-icon icon="edit"/> Leis/Decretos CEMPE</router-link>
      <router-link to="/paginas/editar/380" class="btn"><font-awesome-icon icon="edit"/> Artigos CEMPE</router-link>
      <router-link to="/noticias-camara/cempe" class="btn"><font-awesome-icon icon="edit"/> Notícias CEMPE</router-link>
      <router-link to="/paginas/editar/723" class="btn"><font-awesome-icon icon="edit"/> Vídeos</router-link>
       <router-link to="/paginas/editar/724" class="btn"><font-awesome-icon icon="edit"/> Links</router-link>       
       <router-link to="/paginas/editar/725" class="btn"><font-awesome-icon icon="edit"/> Arquivos</router-link>
      </div>
      <div v-if="item.url === 'ceagro'">
        <router-link to="/paginas/editar/381" class="btn"><font-awesome-icon icon="edit"/> Leis/Decretos CEAGRO</router-link>
        <router-link to="/paginas/editar/382" class="btn"><font-awesome-icon icon="edit"/> Artigos CEAGRO</router-link>
        <router-link to="/noticias-camara/ceagro" class="btn"><font-awesome-icon icon="edit"/> Notícias CEAGRO</router-link>
        <router-link to="/paginas/editar/726" class="btn"><font-awesome-icon icon="edit"/> Vídeos</router-link>
       <router-link to="/paginas/editar/727" class="btn"><font-awesome-icon icon="edit"/> Links</router-link>       
       <router-link to="/paginas/editar/728" class="btn"><font-awesome-icon icon="edit"/> Arquivos</router-link>
      </div>

      <div v-if="item.url === 'ccg'">
        <router-link to="/paginas/editar/502" class="btn"><font-awesome-icon icon="edit"/> Leis/Decretos CCG</router-link>
        <router-link to="/paginas/editar/503" class="btn"><font-awesome-icon icon="edit"/> Artigos CCG</router-link>
        <router-link to="/noticias-camara/ccg" class="btn"><font-awesome-icon icon="edit"/> Notícias CCG</router-link>
        <router-link to="/paginas/editar/729" class="btn"><font-awesome-icon icon="edit"/> Vídeos</router-link>
       <router-link to="/paginas/editar/730" class="btn"><font-awesome-icon icon="edit"/> Links</router-link>       
       <router-link to="/paginas/editar/731" class="btn"><font-awesome-icon icon="edit"/> Arquivos</router-link>
      </div>

      <div v-if="item.url === 'cgpes'">
        <router-link to="/paginas/editar/500" class="btn"><font-awesome-icon icon="edit"/> Leis/Decretos CGPes</router-link>
        <router-link to="/paginas/editar/501" class="btn"><font-awesome-icon icon="edit"/> Artigos CGPes</router-link>
        <router-link to="/noticias-camara/cgpes" class="btn"><font-awesome-icon icon="edit"/> Notícias CGPes</router-link>
        <router-link to="/paginas/editar/732" class="btn"><font-awesome-icon icon="edit"/> Vídeos</router-link>
       <router-link to="/paginas/editar/733" class="btn"><font-awesome-icon icon="edit"/> Links</router-link>       
       <router-link to="/paginas/editar/734" class="btn"><font-awesome-icon icon="edit"/> Arquivos</router-link>
      </div>

      <div v-if="item.url === 'cramulher'">
        <router-link to="/paginas/editar/498" class="btn"><font-awesome-icon icon="edit"/> Leis/Decretos CRA Mulher</router-link>
        <router-link to="/paginas/editar/499" class="btn"><font-awesome-icon icon="edit"/> Artigos CRA Mulher</router-link>
        <router-link to="/noticias-camara/cramulher" class="btn"><font-awesome-icon icon="edit"/> Notícias CRA Mulher</router-link>
        <router-link to="/paginas/editar/735" class="btn"><font-awesome-icon icon="edit"/> Vídeos</router-link>
       <router-link to="/paginas/editar/736" class="btn"><font-awesome-icon icon="edit"/> Links</router-link>       
       <router-link to="/paginas/editar/737" class="btn"><font-awesome-icon icon="edit"/> Arquivos</router-link>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
const axios = require('axios').default;

export default {
  name: 'edititem',
  data() {
    return {
      loading: true,
      saving: false,
      deleting: false,
      savingImg: false,
      itemId: this.$route.params.id,
      item: {
        name: '',
        url: '',
        logo: '',
        manager: '',
        holders: '',
        alternate: '',
        creationDate: '',
        goals: '',
        activities: '',
        events: '',
        contact: '',
        faq: '',
        sponsors: '',
      },
      initDataManager: {
        blocks: []
      },
      initDataHolders: {
        blocks: []
      },
      initDataAlternate: {
        blocks: []
      },
      initDataCreationDate: {
        blocks: []
      },
      initDataGoals: {
        blocks: []
      },
      initDataActivities: {
        blocks: []
      },
      initDataEvents: {
        blocks: []
      },
      initDataContact: {
        blocks: []
      },
      initDataFaq: {
        blocks: []
      },
      initDataSponsors: {
        blocks: []
      }
    }
  },
  mounted() {
    let self = this;
    axios.get(this.$apiUrl + 'councils/item/' + this.itemId)
            .then(response => {
              self.item = response.data.item;
              self.item.manager = JSON.parse(self.item.manager);
              self.item.holders = JSON.parse(self.item.holders);
              self.item.alternate = JSON.parse(self.item.alternate);
              self.item.creationDate = JSON.parse(self.item.creationDate);
              self.item.goals = JSON.parse(self.item.goals);
              self.item.activities = JSON.parse(self.item.activities);
              self.item.events = JSON.parse(self.item.events);
              self.item.contact = JSON.parse(self.item.contact);
              self.item.faq = JSON.parse(self.item.faq);
              self.item.sponsors = JSON.parse(self.item.sponsors);
              self.initDataManager = self.item.manager;
              self.initDataHolders = self.item.holders;
              self.initDataAlternate = self.item.alternate;
              self.initDataCreationDate = self.item.creationDate;
              self.initDataGoals = self.item.goals;
              self.initDataActivities = self.item.activities;
              self.initDataEvents = self.item.events;
              self.initDataContact = self.item.contact;
              self.initDataFaq = self.item.faq;
              self.initDataSponsors = self.item.sponsors;
              self.loading = false;
            })
            .catch(error => {
              this.$alert('', 'Erro', 'error');
              // eslint-disable-next-line no-console
              console.log(error.response.data.error);
              self.loading = false;
            });
  },
  methods: {
    saveItem() {
      let error = false;

      if (!error) {
        this.saving = true;
        let self = this;

        let itemToSave = Object.assign({}, this.item);
        itemToSave.manager = JSON.stringify(itemToSave.manager);
        itemToSave.holders = JSON.stringify(itemToSave.holders);
        itemToSave.alternate = JSON.stringify(itemToSave.alternate);
        itemToSave.creationDate = JSON.stringify(itemToSave.creationDate);
        itemToSave.goals = JSON.stringify(itemToSave.goals);
        itemToSave.activities = JSON.stringify(itemToSave.activities);
        itemToSave.events = JSON.stringify(itemToSave.events);
        itemToSave.contact = JSON.stringify(itemToSave.contact);
        itemToSave.faq = JSON.stringify(itemToSave.faq);
        itemToSave.sponsors = JSON.stringify(itemToSave.sponsors);

        axios.put(this.$apiUrl + 'councils/item/' + this.itemId, itemToSave)
                .then(response => {
                  self.saving = false;
                  this.$alert(response.data.message, 'Sucesso', 'success').then(() => {});
                })
                .catch(error => {
                  this.$alert(error.response.data.message, 'Erro', 'error');
                  // eslint-disable-next-line no-console
                  console.log(error.response.data.error);
                  self.saving = false;
                });
      }
    },
    deleteItem() {
      this.$confirm('Certeza que deseja excluir?', 'Excluir', 'question', {confirmButtonText: 'Sim', cancelButtonText: 'Cancelar'})
              .then(() => {
                this.deleting = true;
                let self = this;

                axios.delete(this.$apiUrl + 'councils/item/' + this.itemId)
                        .then(response => {
                          self.deleting = false;
                          this.$alert(response.data.message, 'Excluído', 'success').then(() => {
                            self.$router.push('/camaras');
                          });
                        })
                        .catch(error => {
                          this.$alert(error.response.data.message, 'Erro', 'error');
                          // eslint-disable-next-line no-console
                          console.log(error.response.data.error);
                          self.deleting = false;
                        });
              })
              .catch(() => {});
    },
    async imgChanged() {
      let self = this;
      let file = this.$refs.img.files[0];
      self.savingImg = true;

      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        axios.post(this.$apiUrl + 'files/upload', formData)
                .then(response => {
                  self.item.logo = response.data.file.key;
                  self.savingImg = false;
                })
                .catch(error => {
                  // eslint-disable-next-line no-console
                  console.log(error);
                  self.savingImg = false;
                });
      }
    },
    formatURL() {
      this.item.url = this.item.url.replace(/\s+/g, '-').toLowerCase();
    },
    saveManager(response) {
      this.item.manager = response;
    },
    onReadyManager() {
      this.$refs['editor_content_manager'].save();
    },
    onChangeManager() {
      this.$refs['editor_content_manager'].save();
    },

    saveHolders(response) {
      this.item.holders = response;
    },
    onReadyHolders() {
      this.$refs['editor_content_holders'].save();
    },
    onChangeHolders() {
      this.$refs['editor_content_holders'].save();
    },

    saveAlternate(response) {
      this.item.alternate = response;
    },
    onReadyAlternate() {
      this.$refs['editor_content_alternate'].save();
    },
    onChangeAlternate() {
      this.$refs['editor_content_alternate'].save();
    },

    saveCreationDate(response) {
      this.item.creationDate = response;
    },
    onReadyCreationDate() {
      this.$refs['editor_content_creation_date'].save();
    },
    onChangeCreationDate() {
      this.$refs['editor_content_creation_date'].save();
    },

    saveGoals(response) {
      this.item.goals = response;
    },
    onReadyGoals() {
      this.$refs['editor_content_goals'].save();
    },
    onChangeGoals() {
      this.$refs['editor_content_goals'].save();
    },

    saveActivities(response) {
      this.item.activities = response;
    },
    onReadyActivities() {
      this.$refs['editor_content_activities'].save();
    },
    onChangeActivities() {
      this.$refs['editor_content_activities'].save();
    },

    saveEvents(response) {
      this.item.events = response;
    },
    onReadyEvents() {
      this.$refs['editor_content_events'].save();
    },
    onChangeEvents() {
      this.$refs['editor_content_events'].save();
    },

    saveContact(response) {
      this.item.contact = response;
    },
    onReadyContact() {
      this.$refs['editor_content_contact'].save();
    },
    onChangeContact() {
      this.$refs['editor_content_contact'].save();
    },

    saveFaq(response) {
      this.item.faq = response;
    },
    onReadyFaq() {
      this.$refs['editor_content_faq'].save();
    },
    onChangeFaq() {
      this.$refs['editor_content_faq'].save();
    },

    saveSponsors(response) {
      this.item.sponsors = response;
    },
    onReadySponsors() {
      this.$refs['editor_content_sponsors'].save();
    },
    onChangeSponsors() {
      this.$refs['editor_content_sponsors'].save();
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/global";
  @import "../../assets/scss/buttons";

  .pages {
    height: 100vh;
    overflow-y: scroll;
    padding: 20px;
    position: relative;

    .back-btn {
      position: absolute;
      top: 20px;
      left: 20px;
      border-radius: 10px;
    }

    .wrapper {
      padding: 50px 200px;
      @media screen and (max-width: 800px) {
        padding: 50px;
      }
    }

    .editor-wrapper {
      text-align: left;
      background-color: #fff;
      border: 1px solid $primary;
      border-radius: 10px;
    }

    label {
      display: block;
      text-align: left;

      &:nth-of-type(1) {
        margin-top: 10px;
      }
    }

    input, select, textarea {
      padding: 10px;
      width: 100%;
      border: 1px solid $primary;
      border-radius: 10px;
      font-size: 1rem;
    }

    select {
      -webkit-appearance: none;
    }

    .save-btn {
      border-radius: 10px;
    }

    img {
      max-width: 50%;
      height: auto;
    }
  }

  .delete-btn {
    background-color: #b13336;
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 10px;

    &:hover {
      background-color: darken(#b13336, 20%);
    }
  }
</style>
